<template>
	<div :class="themeClass" class="passwordCont width-fill margin-t-10 flex-column bg-fff">
		<el-row>
			<el-col class="font-size18 font-weight700 margin-b-10 titleText">账号关联</el-col>
		</el-row>
		<div class="accountCont width-fill bg-fff">
			<div class="rowList flex-row-space-between-center bg-FAFAFA margin-t-6 padding-l-13 margin-l-13 margin-r-13">
				<div class="flex-row-align-center">
					<div class="el-icon-star-off font-size18"></div>
					<div class="margin-l-10">绑定QQ账号
						<em :class="{'bind-active':isQQBind==true,'bind-normal':isQQBind==false}" class="margin-l-10">{{ isQQBind ? "已绑定" : "未绑定" }}</em>
					</div>
				</div>
				<div v-if="isQQBind" @click="qqUnBind" class="pointer margin-r-24 color-theme">解绑</div>
			</div>
			<div class="rowList flex-row-space-between-center bg-FAFAFA margin-t-6 padding-l-13 margin-l-13 margin-r-13">
				<div class="flex-row-align-center">
					<div class="el-icon-star-off font-size18"></div>
					<div class="margin-l-10">绑定微信账号
						<em :class="{'bind-active':isWxBind==true,'bind-normal':isWxBind==false}" class="margin-l-10">{{ isWxBind ? "已绑定" : "未绑定" }}</em>
					</div>
				</div>
				<div v-if="isWxBind" @click="wxUnBind" class="pointer margin-r-24 color-theme">解绑</div>
			</div>
			<div class="rowList flex-row-space-between-center bg-FAFAFA margin-t-6 padding-l-13 margin-l-13 margin-r-13" @click="gochangephone" style="cursor: pointer;">
				<div class="flex-row-align-center">
					<div class="el-icon-star-off font-size18"></div>
					<div class="margin-l-10">修改手机号
						<em  class="margin-l-10 bind-active bind-normal">{{getUserInfo.phone}}</em>
					</div>
				</div>
				<div v-if="isWxBind" @click="wxUnBind" class="pointer margin-r-24 color-theme">解绑</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				isQQBind: false,
				isWxBind: false
			};
		},
		computed: {
			...mapGetters(["getThemeName", 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.getAllBindType();
		},
		methods: {
			gochangephone(){
				this.$router.push('/changephone');
			},
			// 获取用户所有绑定的第三方账号
			getAllBindType() {
				this.ApiRequestPostNOMess('api/mall/sys/account/get-all-bind-type').then(res => {
					let qqbind = false;
					let wxbind = false;
					for (let item of res.obj) {
						switch (item.fTypeCode) {
							case "02":
								wxbind = true;
								break;
							case "03":
								qqbind = true;
								break;
						}
					}
					this.isQQBind = qqbind;
					this.isWxBind = wxbind;
				}, error => {});
			},
			// QQ解绑
			qqUnBind() {
				this.ApiRequestPostNOMess('api/mall/sys/account/qq-un-bind').then(res => {
					this.tipsInfo(res.message)
					this.getAllBindType();
				}, error => {
					console.log("QQ解绑 error", error);
				});
			},
			// 微信解绑
			wxUnBind() {
				this.ApiRequestPostNOMess('api/mall/sys/account/wx-un-bind').then(res => {
					this.tipsInfo(res.message)
					this.getAllBindType();
				}, error => {
					console.log("微信解绑 error ", error);
				});
			}
		}
	};
</script>

<style scoped lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.bind-active {
		color: #00BB00;
	}

	.bind-normal {
		color: #666666;
	}

	.passwordCont {
		height: calc(100% - 10px);

		.titleText {
			padding: 8px 14px;
		}
	}

	.accountCont {
		height: 100%;

		.rowList {

			height: 48px;
			line-height: 48px;

			em {
				font-style: normal;
			}

		}
	}

	.margin-r-24 {
		margin-right: 24px;
	}
</style>
